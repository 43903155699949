import React, { Fragment } from "react";
import css from "./ComponentCard.module.css";
const ComponentCard = (props) => {
  return (
    <div className={css.container}>
      <div className={css.card}>
        <div className={css.card_text}>
          <ul>
            <li>Component Name: {props.data.name}</li>
            <li>Props Enabled: {props.data.propsEnabled}</li>
            <li>Event Listeners: {props.data.eventListener}</li>
            <li>
              Passed Prop
              <ul>
                {props.data.props.map((data, index) => {
                  return (
                    <li key={index}>
                      {data.propname} : {data.propvalue}
                      {" - "}
                      {data.required == "true" ? "required" : "optional"}
                    </li>
                  );
                })}
              </ul>
            </li>

            <li>Width: {props.data.width}</li>
            <li>Version: {props.data.version}</li>
            <li>
              Usage:
              <div className={css.codeBox}>
                <span style={{ color: "#808080" }}>&lt;</span>
                <span style={{ color: "#3AC9B0" }}>{props.data.name}</span>{" "}
                {props.data.props.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <span style={{ color: "#7CDCFE" }}>{data.propname}</span>
                      <span style={{ color: "#D4D4D4" }}>=</span>{" "}
                      <span style={{ color: "#1C8FFF" }}>&#123;</span>
                      <span style={{ color: "#CE834A" }}>{data.propvalue}</span>
                      <span style={{ color: "#1C8FFF" }}>&#125;</span>{" "}
                    </Fragment>
                  );
                })}
                <span style={{ color: "#808080" }}>/&gt;</span>
              </div>
            </li>
          </ul>
        </div>
        <div className={css.disptext}>Display:</div>
        {props.data.module}
      </div>
    </div>
  );
};

export default ComponentCard;
