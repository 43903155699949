import React from "react";
import css from "./PasswordInput.module.css";

const PasswordInput = (props) => {
  return (
    <div className={css.container}>
      <input
        type="password"
        className={css.input_text + " " + css.active}
        value={props.value}
        onChange={(e) => props.setValue(e.currentTarget.value)}
      />
    </div>
  );
};

export default PasswordInput;
