import React from "react";
import css from "./TextInput.module.css";
const TextInput = (props) => {
  return (
    <div className={css.container}>
      <input
        type="text"
        className={css.input_text + " " + css.active}
        value={props.value}
        onChange={(e)=>props.setValue(e.currentTarget.value)}
      />
    </div>
  );
};

export default TextInput;