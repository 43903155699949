import c from "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./Component/Login";
import Home from "./Component/Home/Home";
import Links from "./Component/Links/Links";
function App() {
  return (
    <div className={c.container}>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Links" element={<Links/>}></Route>

      </Routes>
    </div>
  );
}

export default App;
