import React from "react";
import css from "./Links.module.css";
const Links = () => {
  var LINKS = [
    {
      URL: "https://www.github.com",
      LinkText: "Github",
      FAVLINK:
        "https://github.githubassets.com/assets/pinned-octocat-093da3e6fa40.svg",
    },
    {
      URL: "https://chat.openai.com/",
      LinkText: "ChatGPT",
      FAVLINK:
        "https://cdn.oaistatic.com/_next/static/media/apple-touch-icon.59f2e898.png",
    },
    {
      URL: "https://console.firebase.google.com/",
      LinkText: "Firebase",
      FAVLINK:
        "https://www.gstatic.com/devrel-devsite/prod/va65162e8ce9aacc75e4d3c0cd6d166fc6ceaaf184fea0ff0eac1d9b62c0480be/firebase/images/touchicon-180.png",
    },
    {
      URL: "https://console.cloud.google.com/",
      LinkText: "Cloud Console",
      FAVLINK:
        "data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpdD0iIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiBmb2N1c2FibGU9ImZhbHNlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNS4yNzQgNy42ODNsLjc2OC4wMTUgMi4wOTQtMi4wOS4xMDQtLjg4M2E5LjM3OSA5LjM3OSAwIDAwLTE1LjI5NyA0LjU2Yy4yMTYtLjE1OC43MDctLjA0LjcwNy0uMDRsNC4xNzQtLjY4OHMuMjE2LS4zNTMuMzIyLS4zMzFhNS4yMDMgNS4yMDMgMCAwMTcuMTItLjU0M2guMDA4eiIgZmlsbD0iI0VBNDMzNSI+PC9wYXRoPjxwYXRoIGQ9Ik0yMS4wNjQgOS4yODlhOS40MDkgOS40MDkgMCAwMC0yLjgzNC00LjU2N2wtMi45NDYgMi45NDZhNS4yMDQgNS4yMDQgMCAwMTEuOTQ1IDQuMDU5di41MzhhMi42MSAyLjYxIDAgMTEwIDUuMjE3aC01LjIzbC0uNTIuNTI2djMuMTI1bC41Mi41Mmg1LjIxN0E2Ljc3OCA2Ljc3OCAwIDAwMjEuMDY0IDkuMjl6IiBmaWxsPSIjNDI4NUY0Ij48L3BhdGg+PHBhdGggZD0iTTYuNzgxIDIxLjY1NEgxMlYxNy40OEg2Ljc4YTIuNTg3IDIuNTg3IDAgMDEtMS4wNzUtLjI0NmwtLjc1MS4yMzEtMi4wOSAyLjEtLjE4MS43MDZhNi43NDUgNi43NDUgMCAwMDQuMDk3IDEuMzgzeiIgZmlsbD0iIzM0QTg1MyI+PC9wYXRoPjxwYXRoIGQ9Ik02Ljc4MSA4LjA5YTYuNzc5IDYuNzc5IDAgMDAtNC4wOTUgMTIuMTgxbDMuMDI1LTMuMDI1YTIuNjA3IDIuNjA3IDAgMTEzLjQzNy0zLjQzN2wzLjAzNS0zLjAzNUE2Ljc3NiA2Ljc3NiAwIDAwNi43OCA4LjA5MXoiIGZpbGw9IiNGQkJDMDQiPjwvcGF0aD48L3N2Zz4=",
    },
    {
      URL: "https://admin.google.com/",
      LinkText: "Google Admin",
      FAVLINK:
        "https://ssl.gstatic.com/apps/cpanel/resources/logo/brand/admin_48.png",
    },

    {
      URL: "https://code.visualstudio.com/download",
      LinkText: "VS Code",
      FAVLINK: "https://code.visualstudio.com/apple-touch-icon.png",
    },
  ];
  return (
    <div className={css.container}>
      <div className={css.grid}>
        {LINKS.map((data, index) => {
          return (
            <a className={css.item} href={data.URL} target="_blank" key={index}>
              <div className={css.middleWrapper}>
                <div className={css.itemFavIco}>
                  <img src={data.FAVLINK} />
                </div>
                <div className={css.itemLinkText}>{data.LinkText}</div>
                <div className={css.filler}></div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Links;
