import React from "react";
import css from "./Home.module.css";
import TextInput from "../Library/TextInput";
import { Link } from "react-router-dom";
import Links from "../Links/Links";
import Components from "../Components/Components";

const Home = () => {
  return (
    <div className={css.container}>
      <div className={css.header}>Nanofuze Developer Page</div>
      <Links />
      <Components/>
    </div>
  );
};

export default Home;
