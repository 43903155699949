import React, { useState } from "react";
import css from "./Components.module.css";
import TextInput from "../Library/TextInput";
import ComponentCard from "./ComponentCard";
import PasswordInput from "../Library/PasswordInput";

const Components = () => {
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");

  const cards = [
    {
      module: <TextInput Mode={"default"} value={value1} setValue={setValue1} />,
      name: "TextInput",
      propsEnabled: "True",
      eventListener: "All",
      props: [
        { propname: "mode", propvalue: "\"Default\"", required: "true" },
        { propname: "value", propvalue: "value", required: "true" },
        { propname: "setValue", propvalue: "setValue", required: "true" }
      ],
      width: "Fits Parents",
      version: "1.0",
    },
    {
      module: <PasswordInput Mode={"default"} value={value2} setValue={setValue2} />,
      name: "PasswordInput",
      propsEnabled: "True",
      eventListener: "All",
      props: [
        { propname: "mode", propvalue: "\"Default\"", required: "true" },
        { propname: "value", propvalue: "value", required: "true" },
        { propname: "setValue", propvalue: "setValue", required: "true" }
      ],
      width: "Fits Parents",
      version: "1.0",
    },
  ];

  return (
    <div className={css.container}>
      <div className={css.grid}>
        {cards.map((data, index) => {
          return (<div key={index}><ComponentCard data={data} index={index}/></div>)
        })}
      </div>
    </div>
  );
};

export default Components;
